import React from "react"
import { graphql } from "gatsby"

import '../css/related-posts.css';

import placeholder from '../images/default-blog.jpg'

const RelatedPosts = ({ relatedPosts }) => {

  return (
    <div className="container blogRelatedContainer">
      <div className="grid smallGrid align-center">
        <div className="col-sm-12 maincopy">
          <h2>Related</h2>
          <hr />
        </div>
      </div>

      <div className="grid smallGrid">

        { relatedPosts &&
          relatedPosts.map(post => {
            const { id, title, excerpt, link, categories, featuredImage } = post;

            let styleBackground = { 'backgroundImage' : 'url('+placeholder+')' }

            if(featuredImage && featuredImage.node) {
              styleBackground.backgroundImage = 'url('+featuredImage.node.localFile.publicURL+')';
            }

            let categoryList = '';
            if(categories) {
              categories.nodes.map((cat, index) => {
                categoryList += index == 0 ? cat.name : ', '+cat.name;
              })
            }

            return (
              <div key={id} className="col-sm-4">
                <div className="blogBox">
                  <a href={link}>
                    <div className="blogBoxImage" style={styleBackground}></div>
                  </a>
                  <div className="blogBoxBody">
                    <a className="blogBoxTitle" href={link}>{title}</a>
                    <div dangerouslySetInnerHTML={{ __html: excerpt }}></div>
                  </div>
                  <div className="blogBoxFooter">
                    <div className="grid">
                      <div className="col-sm-7">
                        { categoryList != '' &&
                           <p className="blogBoxCat">{categoryList}</p>
                        }
                      </div>
                      <div className="col-sm-5">
                        <a className="blogBoxLink" href={link}>Read More</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }

      </div>
    </div>
  )
}

export default RelatedPosts